@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

:root {
    --product-configurable-dropdown-color: #{$default-primary-base-color};
    --product-configurable-dropdown-arrow-color: #{$default-primary-base-color};
    --product-configurable-dropdown-border-color: #{$default-primary-base-color};
    --product-configurable-dropdown-hover-color: #{$default-primary-base-color};
    --product-configurable-dropdown-radius: 100px;
    --product-configurable-dropdown-radius-open: 25px;
}
