@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

:root {
    --daily-promo-title-background: #{$default-secondary-base-color};
    --daily-promo-content-background: #{$default-primary-base-color};
    --daily-promo-counter-text-color: rgba(255, 255, 255, 0.7);
}

.DailyPromoProduct {
    &-Content {
        @include wide-desktop {
            &::after {
                content: '';
                width: 100%;
                height: 100%;
                background: $white;
                display: block;
                position: absolute;
                right: -18px;
                top: -16px;
                z-index: -1;
            }
        }
    }
}
