@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

.ProductLinks {
    &-SliderWrapper {
        .AdvoxSlider-Heading {
            margin: 0;
            font-size: 22px;
            letter-spacing: 0.07em;
        }
        .ProductsSlider {
            @include tablet {
                padding: 0 20px;
            }

            @include wider-desktop {
                padding: 0;
            }

            .slick-slide > div {
                padding: 40px 0;

                @include mobile {
                    min-width: 172px;
                    max-width: 172px;
                    margin: 0;
                }

                @include tablet {
                    min-width: 226px;
                    max-width: 226px;
                    margin: 0;
                }

                @include wider-desktop {
                    min-width: 256px;
                    max-width: 256px;
                }

                .ProductCard-PriceWrapper,
                .ProductCard-Name,
                .ProductCard-Actions,
                .ProductCard-FigureReview {
                    @include mobile {
                        padding: 0 12px;
                    }
                }

                .AddToCart {
                    min-width: unset;
                    @include mobile {
                        font-size: 12px;
                        min-width: 148px;
                    }
                }
            }

            .slick-prev {
                @include wider-desktop {
                    left: -63px;
                }
            }

            .slick-next {
                @include wider-desktop {
                    right: -63px;
                }
            }

            .slick-list {
                @include wider-desktop {
                    margin-left: -15px;
                    margin-right: -15px;
                    min-width: calc(100% + 30px);
                }
            }
        }
    }
}
