@mixin qty-input {
    border-radius: 100px;
    max-width: 160px;
    border: var(--qty-border);

    @include mobile {
        min-height: 47px;
        max-width: 100px;
    }

    input[type='number'] {
        background-color: var(--qty-input-background);
        width: auto;
        border: none;

        ~ button {
            background-color: var(--qty-button-background);
            border-radius: 50%;
            margin: var(--qty-buttons-padding) 0;
            min-height: var(--qty-button-height);
            min-width: var(--qty-button-width);
            max-height: var(--qty-button-height);
            max-width: var(--qty-button-width);
            @include mobile {
                --qty-buttons-padding: 8px;
                --qty-button-height: 20px;
                --qty-button-width: 20px;
            }

            &:first-of-type {
                border-radius: var(--qty-button-border-radius);
                margin-right: var(--qty-buttons-padding);
            }

            &:last-of-type {
                border-radius: var(--qty-button-border-radius);
                margin-left: var(--qty-buttons-padding);
            }

            &:disabled {
                svg {
                    fill: var(--qty-button-color);
                }
            }

            svg {
                fill: var(--qty-button-color);
            }
        }
    }
}
