@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

.ProductWishlistButton {
    svg {
        width: 20px;
        path {
            stroke: $default-secondary-base-color;
        }
    }

    .ProductWishlistButton-Button_isInWishlist.Button,
    .Button:hover {
        background: $default-secondary-base-color;
        svg path {
            stroke: $white;
        }
    }
}
