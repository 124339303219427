@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

.ProductsSlider {
    .AdvoxSlider-Heading {
        @include from-tablet {
            margin: 10px auto 50px;
        }
        &_headingSide_center {
            margin: 10px auto;
            @include from-tablet {
                margin: 10px auto 50px;
            }
        }
    }
}
