@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

.CategoryProductList {
    @include desktop {
        top: 0;
    }

    &-Page {
        grid-auto-rows: minmax(420px, auto);

        @include narrow-desktop {
            grid-auto-rows: minmax(455px, auto);
        }

        @include mobileAndTablet {
            grid-template-rows: auto;
        }

        &_layout_grid {
            @include desktop {
                margin-top: 0;
            }

            @include tablet {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}
