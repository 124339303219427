@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

.ProductReviewItem {
    padding: 0;
    border: 0;
    margin-top: 60px;
    display: block;
    @include wider-desktop {
        display: block;
    }
    &-RatingSummary {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        @include from-tablet {
            justify-content: flex-start;
        }
    }

    &-RatingsList {
        margin-right: 23px;
        .ProductReviewItem-RatingSummaryItem .ProductReviewRating {
            margin: 0;
            svg {
                top: 5px;
                left: -5px;
                @include wider-desktop {
                    top: 6px;
                    left: -6px;
                }
            }
        }
    }

    &-ReviewAuthor {
        margin: 0;
        font-weight: normal;
        font-size: 15px;
        line-height: 150%;
        color: $black;
        font-family: $font-secondary;
    }

    &-ReviewDetails {
        margin-top: 24px;
        font-weight: normal;
        font-size: 15px;
        line-height: 150%;
        font-family: $font-secondary;
        color: $grey3;
    }
}
