@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --option-string-active-color: #{$white};
    --option-string-active-background: #{$default-primary-base-color};
    --product-attribute-color-border-radius: 5px;
    --product-attribute-color-border-color: #{$default-primary-base-color};
    --product-attribute-text-border-radius: 5px;
    --option-size: 40px;
}

[dir='ltr'] .ProductAttributeValue {
    &-String {
        --option-text-color: #{$purple30};
        --option-background-color: #{$purple5};
        padding: 9px 20px;
        overflow: hidden;

        &_isSelected {
            color: var(--option-string-active-color);
        }
        border-radius: 25px;
    }

    &_isNotAvailable .ProductAttributeValue-String:before {
        right: -25px;
    }

    &-Text {
        label {
            &:first-of-type {
                .input-control {
                    border-color: $default-primary-base-color;
                }
            }
        }
        &_isSelected {
            color: $default-primary-base-color;
        }
    }

    &-Text:hover .input-control::after,
    &-Text:focus .input-control::after {
        --checkmark-color: #{$default-primary-base-color};
    }

    &-Color,
    &-Image-Overlay,
    &-Image,
    &-Color:before,
    &-Image:before,
    &-Image-Overlay:before {
        border-radius: 25px;
    }
}
