@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

:root {
    --qty-background: #{$white};
    --qty-button-background-disabled: #{$blue10};
    --qty-button-border-disabled: #{$blue10};
    --qty-input-background: #{$white};
    --qty-border: 1px solid #{$default-primary-base-color};
}
[dir='ltr'] .ProductActions {
    &-Title {
        color: $default-primary-base-color;
    }

    &-AskForProduct {
        a {
            color: $default-primary-base-color;
            line-height: 1.3;
        }

        svg {
            fill: $default-secondary-base-color;
            stroke: $default-secondary-base-color;
        }
    }

    &-AddToCartWrapper .ProductActions-AddToCart,
    &-AddToCartMobile .ProductActions-AddToCart {
        --button-border: $default-secondary-base-color;
        svg path {
            stroke: $default-primary-base-color;
        }
    }

    &-DeliveryCost,
    &-DeliveryTime {
        color: $default-primary-base-color;
    }

    &-DeliveryCost {
        button {
            color: $default-primary-base-color;
        }
    }

    .ProductWishlistButton,
    .ProductCompareButton {
        button:hover {
            background: $default-primary-base-color;
        }
    }

    &-AddToCartWrapper .ProductAlerts-InStock,
    &-AddToCartMobile .ProductAlerts-InStock {
        background: $default-primary-base-color;
        border-color: $default-primary-base-color;
    }
}
