@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

[dir='ltr'] .ProductCard {
    --product-cart-border-radius: 20px;
    --product-cart-add-to-cart-background: #{$purple50};

    &-FigureReview,
    &-Content {
        padding-left: 10px;
        padding-right: 10px;
    }
    &:hover {
        box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);
        @include desktop {
            box-shadow: 0 10px 25px 0 rgba(76, 71, 173, 0.1);
        }
        .ProductCard-Actions {
            padding: 0;
        }
        .AddToCart:disabled {
            --button-border: #{$grey-light};
            background-color: $grey-light;
            width: 100%;
            max-width: 236px;

            svg {
                path {
                    stroke: $white;
                }
            }
            span {
                text-indent: unset;
                margin-left: 12px;
                max-width: unset;
            }
        }
        .AddToCart:not([disabled]) {
            --button-border: var(--product-cart-add-to-cart-background);
            background-color: var(--product-cart-add-to-cart-background);
            width: 100%;
            max-width: 236px;

            svg {
                fill: var(--product-cart-add-to-cart-background);
                path {
                    stroke: $white;
                }
            }
            span {
                text-indent: unset;
                margin-left: 12px;
                max-width: unset;
            }
        }
    }
}
