@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

.ProductAttributes {
    &-Attributes {
        display: block;

        @include from-tablet {
            padding: 0 45px;
        }
    }

    &-Attribute {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: flex-start;
    }
    &-AttributeLabel,
    &-ValueLabel {
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        font-family: $font-secondary;
        color: $black;
        border: 0;
        padding: 0;
        margin-bottom: 25px;

        @include from-tablet {
            font-size: 15px;
        }
    }

    &-AttributeLabel {
        max-width: calc(50% - 12px);
        margin-right: 12px;

        @include from-tablet {
            min-width: 190px;
            max-width: 190px;
        }
    }
    &-ValueLabel {
        color: $grey3;
        flex: 1;
    }
}
